import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/System/WebUI/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import TilesTable from 'components/Web_User_Interface/1440p_Series/System/WebUI/tilesTable';
import VideoWebUIWidgets from 'components/Web_User_Interface/1440p_Series/System/WebUI/VideoWebUIWidgets';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // WebUI",
  "path": "/Web_User_Interface/1440p_Series/System/WebUI/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Web User Interface Settings",
  "image": "./WebUI_1440p_SearchThumb_System_WebUI.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_System_WebUI.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='System Menu // WebUI' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Web User Interface Settings' image='/images/Search/WebUI_1440p_SearchThumb_System_WebUI.png' twitter='/images/Search/WebUI_1440p_SearchThumb_System_WebUI.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/System/WebUI/' locationFR='/fr/Web_User_Interface/1440p_Series/System/WebUI/' crumbLabel="WebUI" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/91ddb286fa94954f4fd22c47bb4dc664/fbf08/1440p_Settings_System_WebUI.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7rAAAO6wFxzYGVAAADxklEQVQ4y32T60+bZRiH+2+YaFmi9gBxQoC+LYzzGLQc2vftiR4glLZABgPPExiM4XSOuMzNMWGgOE4OB8ENmjEYOB1jgaFOo8YvJrAwjBgQMtQtcbm0L0zJNH64cud+nuTK735yP4oorRpDlgPzy53srniX3ZWtZFa2klreQnLpKZLL/puk0ncwVp/BcaCP7OozqGLTUT7+GAqt+mlS84vwN4dwNnyE8+AArsZBHAcHsDUMYG8YfISts/rzeBr7cdX3UfLmMIlGN6qnnkSh0WoRdHE4rGacNouMXcrHaTUj5Rux5GUj5hkR5bpFbhaixYJodyHZXZhFK5kZmRj0SSi0Wi0GgwG3243L5aKgwIXNZkOSrEiShChJ26oVyWpHys9BqjyK/e2vcJz4GqnuHJlpaQjCrk2hXq/H5SmkwFOIy1uI0+3F6fIg2RxINjtiuFpt28QiFm8Z1rI67GV12HxVZGbseSiMRNDF4z89zN6h7yjpvUnFwC18fXME+r+kuGeW4OC3eJt7EC1mxFwTnkMt+Ae+4Uj/GDc+Haet9TSpKekkGMLCyEh08XHs7Z+j9uZ9gmPLvHp9ncD4z1RcXSMwtkz1jXsUt11GNOdjycmm6NiHVM38QfvMIneXl7jw8RDpqckkJe5CodFo0Ovi8XVNE5hYxR+6TWD0R0ou3sYfWsJ3YZ7SyV8oahlBNJtlofdoN/7JdfaFfqBp6ieeax9hT1oyhnBClVqNQYgn2DtD5bW7lI4uUTe1QiC0xKHpVQIjizw/vYGv9RKWrYSe5l72Xf+VktAiwc9+w9s2TlZqCoaEZBRqtZoEvUDJ2SkCV1bwjyzgv3SHwPACpaE7+C/OE5xYoejUsPyGcsLmbsqvrhMYmafskzV8bZfRxexEpdZuCoX4OMrP3+KluQcEJ1apmtogOLlG7ezvcv/K5w/wd1yRE4o52bjCCafvURxa5IXpDflup1bFE8qIrZH1Ap7at/Ae6cTd1IbncDvu19rwvt6x2b/RifPFJnld8kxG9h/v4IPZBRpHv8c3voqvdZToKA0RO3agUKlUJCQkYM3PxWzKkkf6F6YsxLwceeGzsrPpfK+D+ytLnJubp+oLCHZd49lINcqwMDxy+KdYbTZEyfo/bP6YnJxc9tfU0t11lvqT7+M9MYT3wHGin4lCGRHxT0Kn04ndbv8bh8PxCE4cVhFHWQ2Omi5MVSc5bLTQk5bOMVMu0bGxKJXKzT2MiYkhKSlJFj9EEAR0Ot02tnpDIobkDJkUwUC6oCdF0KPWaNjxV8I/AaapzZTvth9RAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91ddb286fa94954f4fd22c47bb4dc664/e4706/1440p_Settings_System_WebUI.avif 230w", "/en/static/91ddb286fa94954f4fd22c47bb4dc664/d1af7/1440p_Settings_System_WebUI.avif 460w", "/en/static/91ddb286fa94954f4fd22c47bb4dc664/7f308/1440p_Settings_System_WebUI.avif 920w", "/en/static/91ddb286fa94954f4fd22c47bb4dc664/4346e/1440p_Settings_System_WebUI.avif 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/91ddb286fa94954f4fd22c47bb4dc664/a0b58/1440p_Settings_System_WebUI.webp 230w", "/en/static/91ddb286fa94954f4fd22c47bb4dc664/bc10c/1440p_Settings_System_WebUI.webp 460w", "/en/static/91ddb286fa94954f4fd22c47bb4dc664/966d8/1440p_Settings_System_WebUI.webp 920w", "/en/static/91ddb286fa94954f4fd22c47bb4dc664/c76c7/1440p_Settings_System_WebUI.webp 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91ddb286fa94954f4fd22c47bb4dc664/81c8e/1440p_Settings_System_WebUI.png 230w", "/en/static/91ddb286fa94954f4fd22c47bb4dc664/08a84/1440p_Settings_System_WebUI.png 460w", "/en/static/91ddb286fa94954f4fd22c47bb4dc664/c0255/1440p_Settings_System_WebUI.png 920w", "/en/static/91ddb286fa94954f4fd22c47bb4dc664/fbf08/1440p_Settings_System_WebUI.png 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/91ddb286fa94954f4fd22c47bb4dc664/c0255/1440p_Settings_System_WebUI.png",
              "alt": "Web User Interface - 1440p Series - System WebUI",
              "title": "Web User Interface - 1440p Series - System WebUI",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/*
     import WebUITable from 'components/Web_User_Interface/1440p_Series/System/WebUI/webUITable'
     <EuiSpacer />
     <WebUITable />
     <EuiSpacer />
     */}
    <h3 {...{
      "id": "personalize-the--quick-access-bar",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#personalize-the--quick-access-bar",
        "aria-label": "personalize the  quick access bar permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Personalize the  Quick Access Bar`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoWebUIWidgets mdxType="VideoWebUIWidgets" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The  access bar allows you to quickly access frequently used functions on the right side of the main page with one click. You can customize the arrangement as well as the selection in this menu according to your needs.`}</p>
    <h4 {...{
      "id": "drag--drop",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#drag--drop",
        "aria-label": "drag  drop permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Drag & Drop`}</h4>
    <p>{`To adjust the positions or the individual buttons in the  access bar, you just need to drag the desired button to the desired position.`}</p>
    <p><strong parentName="p">{`Remark`}</strong>{`: With Internet Explorer as well as the Edge browser you must first select a button with a click. It will then be highlighted in orange. Then select the position in which you want to place the marked button. Valid options are displayed in orange when you move the mouse over them.`}</p>
    <h3 {...{
      "id": "quick-access-tiles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#quick-access-tiles",
        "aria-label": "quick access tiles permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quick Access Tiles`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <TilesTable mdxType="TilesTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "digital-zoom",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#digital-zoom",
        "aria-label": "digital zoom permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Digital Zoom`}</h3>
    <p>{`Activate the digital Zoom controls in the lower right to be able to increase the center of your cameras live video by up to `}<strong parentName="p">{`6 times`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4b290e93b9a61a5f6207b5cabf0352fb/e2310/1440p_Settings_System_WebUI_digital_Zoom.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7rAAAO6wFxzYGVAAADNklEQVQ4yx2SaU8bBxiEX+OUJlWDOOyEy/jEx+7ie9cXPtfrA19gik0dYjcEIWjUJoU2SqtKqfiQJh+qSuk/6A/oX3wq/GE0mnmlGWn0SrfbodVs0m615txpt2m3mjSsOo16HdM0Mc3aHFa9PvebDYtSscjt7Q3/fP6MoeuUSkUaloV4QwresIonrOGOaOxE9uZwRaK4lTh6No+RK8zZp8Vxhe/vUVzBCIlMlmy5xpYvwKbHT1DREFlyICvryMoGsrqFrO4gDg/i9CFPAiy6IqztRlkO7M313Hd4EecOiytryGMnttUNFpadrGy6EHG4sD31Iut+bJtBbNsqNncMmzeJ3Z9GXCmyBYNE1kB2kth9CcQd50uPitPj4cFWENtGALvTxZrLi8gTH7IZRLYjiCeGBHQknGdBK/EgVkH2ahT20+wXdUStYNf2EaXAQ8UgoEV4FIgiWwr3OcvuICL3DR4VWyCJKDkkVsGWtpCkhRhtJNOl1ChhdaqI0cKesZB0na+MCmouhV0x0LIGqWSMx/77DV0hZDeOKBkkWsCWbSDlHvHZS9qTIV+YfRyZAk49j9SOkEoPKXX4utKi1S0hiSLTcZ2PUxNHJI5sxlPYQjFWUhke5aos1A6QSovLqxkf3l+xcNAn2G4QMWs8HU9YOhzxsHWIuzPgYGCyaJRpDeroiQjrIRV5/aLHyTd9ak2L5XCY9VyepYjGtj/I3fsf8D07I3zUR7VqDGcnDL6boPX6XJ+PaQxMtp/NaFzOSEd9GEkFefvmgldnJY4HFczeIbuaiicUQjV0Pn16y/T2NbXZc8zTYzKzKfvDAZNhn/++H9MeNXlz9xNXv//IoNckn9aQf//Q+fvDIaNRh9NmGT1x/+gB9go5Pt694sW0Q71T4vzmJb/8+SsXP085Ph/x180pv133eHfZp1YvoekZNjxu5N3Ux9kwR1RP49xaxx0K4U+lqdTz5CfXhGt9YlYV/ahOvJMhM9onO6xw0C9Qrqapdiy64xPK1QJ7yRiyEwzidHvn6a6QileJ4k9nKVpFas8v0I8vaR4dUyikCWfy+KIJNEVjnIwR0FRSrQO6k2+5bmeZNfL8D+eGp1AEB9zuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b290e93b9a61a5f6207b5cabf0352fb/e4706/1440p_Settings_System_WebUI_digital_Zoom.avif 230w", "/en/static/4b290e93b9a61a5f6207b5cabf0352fb/d1af7/1440p_Settings_System_WebUI_digital_Zoom.avif 460w", "/en/static/4b290e93b9a61a5f6207b5cabf0352fb/7f308/1440p_Settings_System_WebUI_digital_Zoom.avif 920w", "/en/static/4b290e93b9a61a5f6207b5cabf0352fb/face5/1440p_Settings_System_WebUI_digital_Zoom.avif 968w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4b290e93b9a61a5f6207b5cabf0352fb/a0b58/1440p_Settings_System_WebUI_digital_Zoom.webp 230w", "/en/static/4b290e93b9a61a5f6207b5cabf0352fb/bc10c/1440p_Settings_System_WebUI_digital_Zoom.webp 460w", "/en/static/4b290e93b9a61a5f6207b5cabf0352fb/966d8/1440p_Settings_System_WebUI_digital_Zoom.webp 920w", "/en/static/4b290e93b9a61a5f6207b5cabf0352fb/61c9b/1440p_Settings_System_WebUI_digital_Zoom.webp 968w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b290e93b9a61a5f6207b5cabf0352fb/81c8e/1440p_Settings_System_WebUI_digital_Zoom.png 230w", "/en/static/4b290e93b9a61a5f6207b5cabf0352fb/08a84/1440p_Settings_System_WebUI_digital_Zoom.png 460w", "/en/static/4b290e93b9a61a5f6207b5cabf0352fb/c0255/1440p_Settings_System_WebUI_digital_Zoom.png 920w", "/en/static/4b290e93b9a61a5f6207b5cabf0352fb/e2310/1440p_Settings_System_WebUI_digital_Zoom.png 968w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4b290e93b9a61a5f6207b5cabf0352fb/c0255/1440p_Settings_System_WebUI_digital_Zoom.png",
              "alt": "Web User Interface - 1440p Series - System WebUI",
              "title": "Web User Interface - 1440p Series - System WebUI",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      